<template>
  <div id="Portrait" class="wrap">
    <van-nav-bar :fixed="true" placeholder :title="$t('My.PersonalInformation.portrait.SetAvatar')" left-arrow
      @click-left="onClickLeft" />
    <!-- <van-uploader v-model="imgFile" :result-type="SubmitEvent"></van-uploader> -->
    <div class="header">
      <van-uploader :after-read="afterRead">
        <div class="avatar_wrap">
          <van-image round width="3.75rem" height="3.75rem" :src="getImage(DataArr.url)" />
          <div class="icon_photo">
            <img :src="require('@/assets/icon/photo.png')" alt="">
          </div>
        </div>
      </van-uploader>
      <span class="button" @click="SubmitEvent()">{{
      $t("My.PersonalInformation.portrait.button")
      }}</span>
    </div>
    <div class="header-One"></div>
    <div class="content">
      <div class="content-item" v-for="index in 19" :key="index">
        <van-image round width="100%" height="100%"
          @click="ImgEvent('/uploads/system/userimg/userimg_' + index + '.png')" :src="
            verConfig.url + 'uploads/system/userimg/userimg_' + index + '.png'
          " />
      </div>
    </div>
  </div>
</template>

<script>
import ImgData from "@/utils/ImgData"; // 封装的头像数据
import { REQUEST_API } from "@/http/api"; // 引入封装的axios
import { getUserInfoNew, editAvatar } from "@/api";
import { $upload, $post, domain } from "@/utils/request.js";
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Image,
  Toast,
  Uploader,
} from "vant";

export default {
  name: "Portrait",
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [Toast.name]: Toast,
    [Uploader.name]: Uploader,
  },
  data() {
    return {
      DataArr: {
        url: "",
        icon: "", // 传递给后台的
      },
      ImgArr: ImgData, // img图片资源
      userInfo: {},
      avatar: ""
    };
  },
  methods: {
    getUserInfo() {
      this.userInfo = JSON.parse(this.$cookie.get("userInfo"));
    },
    imgUrlToFile(url) {
      var self = this;
      var imgLink = url;
      var tempImage = new Image();
      tempImage.onload = function () {
        var base64 = self.getBase64Image(tempImage);
        var imgblob = self.base64toBlob(base64);
        var filename = self.getFileName(self.ruleForm.coverUrl);
        self.ruleForm.coverFile = self.blobToFile(imgblob, filename);
      };
      tempImage.src = imgLink;
    },
    async afterRead(event) {
      event.status = 'uploading';
      event.message = this.$t("upload.uploading");
      this.DataArr.url = event.content;
      const formdata = new FormData();
      try {
        const ImgData = await this.compressImg(event.file);
        const fileData = new File([ImgData], event.file.name, { type: ImgData.type })
        formdata.append("image", fileData);
      } catch (error) {
        formdata.append("image", event.file);
      }
      $upload({ url: "/users/upload", data: formdata })
        .then((res) => {
          if (res.ret === 1) {
            event.status = 'success';
            event.message = '';
            this.imgFile = res.data.path
            // this.formData.card_front = res.data.path;
          } else {
            event.status = 'failed';
            event.message = this.$t("upload.failed");
            Toast(this.$t("upload.failed"))
            // this.$toast(this.$t("uploadErr"));
          }
        })
        .catch((err) => {
          event.status = 'failed';
          event.message = this.$t("upload.failed");
          Toast(this.$t("upload.failed"))
          // this.$toast(this.$t("uploadErr"));
        });
    },
    // 压缩图片
    async compressImg(file) {
      const img = await this.readImg(file);
      const type = img.type;
      return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        const { width: originWidth, height: originHeight } = img
        // 最大尺寸限制
        const maxWidth = 1000
        const maxHeight = 1000
        // 目标尺寸
        let targetWidth = originWidth
        let targetHeight = originHeight
        if (originWidth > maxWidth || originHeight > maxHeight) {
          if (originWidth / originHeight > 1) {
            //  宽图片
            targetWidth = maxWidth
            targetHeight = Math.round(maxWidth * (originHeight / originWidth))
          } else {
            // 高图片
            targetHeight = maxHeight
            targetWidth = Math.round(maxHeight * (originWidth / originHeight))
          }
        }
        canvas.width = targetWidth
        canvas.height = targetHeight
        context.clearRect(0, 0, targetWidth, targetHeight)
        // 图片绘制
        context.drawImage(img, 0, 0, targetWidth, targetHeight)
        canvas.toBlob(function (blob) {
          resolve(blob)
        }, type || 'image/png')
      })
    },
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1);
    },
    // 图片的点击事件
    ImgEvent(url) {
      this.DataArr.icon = url;
      this.DataArr.url = url;
      this.imgFile = url;
    },
    SubmitEvent() {
      // console.log(this.imgFile)
      this.$api.editAvatar(
        {
          userimg: this.imgFile,
        },
        (res) => {
          // console.log(res)
          // console.log(res.data.data);
          this.$router.go(-1)
          // Toast(res.data.data.msg)
        }
      );
    },
    getImage(url) {
      const reg = new RegExp("http");
      const dataReg = new RegExp("data:")
      if (reg.test(url)) {
        return url
      }
      if (dataReg.test(url)) {
        return url
      }
      return this.verConfig.url + url
    }
  },
  created() {
    this.getUserInfo();
    if (this.userInfo.userimg) {
      this.DataArr.url = this.userInfo.userimg;
    } else {
      this.DataArr.url = require("@/assets/Img/myImg/head.png");
    }
  },
};
</script>

<style lang="less">
#Portrait {
  width: 100%;
  height: 100%;

  .header-One {
    opacity: 0;
    width: 100%;
    height: 7.8rem;
  }

  .header {
    position: fixed;
    width: 100%;
    height: 7.8rem;
    z-index: 1;
    background: var(--nav-background,red);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .avatar_wrap {
      position: relative;

      .icon_photo {
        position: absolute;
        top: 0;
        left: 0;
        @size: 3.75rem;
        width: @size;
        height: @size;
        display: flex;
        justify-content: center;
        align-items: center;

        >img {
          width: calc(@size / 1.5);
          height: calc(@size / 1.5);
          // opacity: .6;
        }
      }
    }

    img {
      border-radius: 50px;
    }

    .van-image {
      margin-bottom: 0.7rem;
    }

    >.button {
      padding: 0.25rem 1.3rem;
      // border: 1px solid #fff;
      border: 1px solid var(--theme);
      border-radius: 0.35rem;
      // color: #fff;
      color: var(--theme);
      font-size: 0.875rem;
    }
  }

  .content {
    // border-top: 0.625rem solid #f2f2f2;
    border-top: 0.625rem solid var(--bg);
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    // justify-content: space-between;
    >.content-item {
      width: 20%;
      height: 3rem;
      padding: 0.625rem 0;

      >div {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      img {
        width: 3rem;
        border-radius: 50px;
      }
    }
  }
}
</style>